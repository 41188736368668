@import '~antd/dist/antd.css';

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
html, body, #root{
  height: 100%;
  margin: 0;
  padding: 0;
}
